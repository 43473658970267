import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "../styles/missingPurchase.scss";
import { ValidatorForm } from "react-form-validator-core";
import MissingOrderForm from "./MissingOrderForm";
import MissingClickForm from "./MissingClickForm";
import axios from "axios";
import LoaderComponent from "./LoaderComponent";
import { toast } from "react-toastify";
import config from "./utils/config";
import { useNavigate } from 'react-router-dom';
import ClaimForm from "./MissingClaimForm";
// import fileDownload from 'js-file-download'
import { formatToSwedish } from "./utils/numberFormatUtils";

export default function MissingPurchaseModal(props) {

  const [isLoading, setisLoading] = useState(false);
  const toggleModal = () => {
    props.toggle();
  };
  const navigate=useNavigate();
  const notify = (message) => toast.success(message, {
    toastId: 'success1',
})

  const updateClaimData = async (new_data) => {
    // setisLoading(true)

    // console.log("Missing Purchase modal ");
    // console.log(new_data)

    let bearer_token = "";
    await config.localforage.getItem("token").then((token) => {
      bearer_token = token;
    });
    // console.log("Current token : ", bearer_token);
    let req_config = {
      method: "post",
      url: `${config.SERVER_URL}/updateclaim?claimid=${props.data.id}&status=${new_data.status}&orderValue=${new_data.orderValue}&orderValueCurrencyCode=${new_data.selectedCurrency}&commisionValue=${new_data.commisionValue}&rewardCategory=${new_data.rewardCategory}&rewardCommisionPercentage=${new_data.rewardCommisionPercentage}&purchaseId=${new_data.purchaseId}&claimedOrderNumber=${new_data.orderNumber}&claimedOrderDate=${new_data.orderDate}`,
      headers: {
        Authorization: `Bearer ${bearer_token}`,
      },
    };

    axios(req_config)
        .then((response) => {
          setisLoading(false);
          notify("Claim status has been updated");
          toggleModal();
          props.loadData();
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            // If 401 error, navigate to login page
           navigate("/");
          } else {
          setisLoading(false);
          console.error(error);
          if(error.response){
            notify(error.response.data.message);
          }else{
            navigate(`/apology/0/0`); 
          }
          
        }
      });
  }

  const renderTableData = (name) => {
    return <font style={{ verticalAlign: "inherit" }}>{name}</font>;
  };

  const downloadAttachment = async (filePath) => {
    const fileName = filePath.split("/").pop();
  
    let bearer_token = "";
  
    await config.localforage.getItem("token").then((token) => {
      bearer_token = token;
    });
    // console.log("Current token : ", bearer_token);
  
    let req_config = {
      method: 'get',
      url: `${config.SERVER_URL}/getfile?fileName=${fileName}`,
      headers: {
        'Authorization': `Bearer ${bearer_token}`
      },
      responseType: 'blob', 
    };
  
    axios(req_config)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
  
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            navigate("/");
          } else if (error.response.status === 404) {
            // Handle 404 Not Found
            // console.log("File not found:", error.response.data); // Log the error response
            toast.warning("File not found", {
              toastId: 'warning1',
            });
            // You can add additional error handling or user feedback here
          } else {
            navigate(`/apology/0/0`);
            // console.log(error);
          }
        } else {
          // Handle other non-response errors here
          // console.log(error);
        }
      });
  }
  

  const formElements = () => {
    return (
      <div style={{overflowX:"auto"}}>
        <table className="mp-table">
          <tbody>
            <tr>
              <td>{renderTableData("Claim ID")}</td>
              <td>{renderTableData(props.data.id)}</td>
            </tr>
            <tr>
              <td>{renderTableData("Order Submitted Date")}</td>
              <td>{renderTableData(props.data.createDate)}</td>
            </tr>
            <tr>
              <td>{renderTableData("Shop Name")}</td>
              <td>{renderTableData(props.data.claimedShopName)}</td>
            </tr>
            <tr>
              <td>{renderTableData("Claime Order Number")}</td>
              <td>{renderTableData(props.data.claimedOrderNumber)}</td>
            </tr>
            <tr>
              <td>{renderTableData("User Id")}</td>
              <td>{renderTableData(props.data.userId)}</td>
            </tr>
            <tr>
              <td>{renderTableData("First Name")}</td>
              <td>{renderTableData(props.data.claimedUserFirstName)}</td>
            </tr>
            <tr>
              <td>{renderTableData("Last Name")}</td>
              <td>{renderTableData(props.data.claimedUserLastName)}</td>
            </tr>
            <tr>
              <td>{renderTableData("Email")}</td>
              <td>{renderTableData(props.data.claimedUserEmail)}</td>
            </tr>
            <tr>
              <td>{renderTableData("Partner Name")}</td>
              <td>{renderTableData(props.data.partnerName)}</td>
            </tr>
            <tr>
              <td>{renderTableData("Commision Value")}</td>
              <td>{renderTableData(props.data.commisionValue ? formatToSwedish(props.data.commisionValue.toFixed(2)) : "")}</td>
            </tr>
            <tr>
              <td>{renderTableData("Order Value")}</td>
              <td>{renderTableData(props.data.orderValue ? formatToSwedish(props.data.orderValue) : "")}</td>
            </tr>
            { props.data.attachmentPath != null &&  
              <tr>
                <td>{renderTableData("Attachment Path")}</td>
                <td>
                  <a href="#" onClick={() => downloadAttachment(props.data.attachmentPath)}>Download</a>
                </td>
              </tr>
            }
            
            {/* <tr>
              <td>{renderTableData("User Claim Status")}</td>
              <td>{renderTableData(props.data.userClaimStatus ? "true" : "false")}</td>
            </tr> */}
            <tr>
              <td>{renderTableData("Claimed Order Date")}</td>
              <td>{renderTableData(props.data.claimedOrderDate)}</td>
            </tr>
            {/* <tr>
              <td>{renderTableData("Is Manual Intervention Required")}</td>
              <td>
                {renderTableData(props.data.isManualInterventionRequired ? "Yes" : "No")}
              </td>
            </tr> */}
            <tr>
              <td>{renderTableData("Status")}</td>
              <td>{renderTableData(props.data.status )}</td>
            </tr>
            <tr>
              <td>{renderTableData("Click Id")}</td>
              <td>{renderTableData(props.data.clickId)}</td>
            </tr>
            <tr>
              <td>{renderTableData("Affiliate Network")}</td>
              <td>{renderTableData(props.data.affiliateNetwork)}</td>
            </tr>
            <tr>
              <td>{renderTableData("Order Value Currency Code")}</td>
              <td>{renderTableData(props.data.orderValueCurrencyCode)}</td>
            </tr>
            <tr>
              <td>{renderTableData("Network Response")}</td>
              <td>{renderTableData(props.data.networkResponse ? props.data.networkResponse : "No Response")}</td>
            </tr>
          </tbody>
        </table>

        <hr />
      </div>
    );
  };

  return (
    <div className="container">
      <Modal
        // className="missing_purchase_content"
        isOpen={props.open}
        toggle={toggleModal}
        contentClassName="missing_purchase_content"
        size="100%"
        
      >
         <div className="container">
         <ModalHeader toggle={toggleModal}  >
         <div className="">
            <span className="heading"></span>
          </div>
          </ModalHeader>
        </div>
        <div className="container">
        <ModalHeader  className="missing_purchase_heading bg-blue justify-content-center" >
          <div className="">
            <span className="heading" >Claim Details</span>
          </div>
        </ModalHeader>
        <ModalBody>
        {isLoading && <LoaderComponent />}
          {/* <ValidatorForm autoComplete="off"> */}
            {formElements()}
            { props.data.status === "PENDING" && <>
              {/* <MissingOrderForm data={props.data} /> */}
              <ClaimForm data={props.data} updateClaimData={updateClaimData} />
              {/* <MissingClickForm partnerId={props.partnerId} data={props.data} updateClaimData={updateClaimData} /> */}
            </> }
            
          {/* </ValidatorForm> */}
        </ModalBody>
        </div>
      </Modal>
    </div>
  );
}
